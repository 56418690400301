import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
const Templates = Loadable(lazy(() => import("app/views/templates/Templates")));
const TemplateDetail = Loadable(lazy(() => import("app/views/template-detail/TemplateDetail")));
const Groups = Loadable(lazy(() => import("app/views/groups/Groups")));
const GroupDetail = Loadable(lazy(() => import("app/views/groups/GroupDetail")));
const GroupCreate = Loadable(lazy(() => import("app/views/groups/GroupCreate")));
const Customers = Loadable(lazy(() => import("app/views/customers/Customers")));
const CustomerCreate = Loadable(lazy(() => import("app/views/customers/Create")));
const CustomerDetail = Loadable(lazy(() => import("app/views/customers/Detail")));
const Messages = Loadable(lazy(() => import("app/views/messages/Messages")));
const Campaigns = Loadable(lazy(() => import("app/views/campaigns/Campaigns")));
const CampaignCreate = Loadable(lazy(() => import("app/views/campaigns/Create")));
const CampaignDetail = Loadable(lazy(() => import("app/views/campaigns/Detail")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      // { path: "/dashboard/default", element: <Analytics />, auth: authRoles.admin },
      { path: "/dashboard/templates", element:<Suspense fallback={<div>Loading...</div>}> <Templates /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/templates/detail", element:<Suspense fallback={<div>Loading...</div>}> <TemplateDetail /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/groups", element:<Suspense fallback={<div>Loading...</div>}> <Groups /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/groups/create", element:<Suspense fallback={<div>Loading...</div>}> <GroupCreate /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/groups/:id", element:<Suspense fallback={<div>Loading...</div>}> <GroupDetail /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/customers", element:<Suspense fallback={<div>Loading...</div>}> <Customers /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/customers/:id", element:<Suspense fallback={<div>Loading...</div>}> <CustomerDetail /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/customers/create", element:<Suspense fallback={<div>Loading...</div>}> <CustomerCreate /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/messages", element:<Suspense fallback={<div>Loading...</div>}> <Messages /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/campaigns", element:<Suspense fallback={<div>Loading...</div>}> <Campaigns /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/campaigns/create", element:<Suspense fallback={<div>Loading...</div>}> <CampaignCreate /> </Suspense>, auth: authRoles.admin },
      { path: "/dashboard/campaigns/:id", element:<Suspense fallback={<div>Loading...</div>}> <CampaignDetail /> </Suspense>, auth: authRoles.admin },
      // e-chart route
      // { path: "/charts/echarts", element: <AppEchart />, auth: authRoles.editor }
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/customers" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
